import { ArtFrame } from './graphics/ArtFrame'

setBodyClass()
startArtwork()

function setBodyClass() {
  const path = window.location.pathname

  if (path === '/') {
    document.body.classList.add('home')
  } else if (path.includes('about')) {
    document.body.classList.add('about')
  } else if (path.includes('works')) {
    document.body.classList.add('works')
  } else if (path.includes('explorations')) {
    document.body.classList.add('explorations')
  } else if (path.includes('contact')) {
    document.body.classList.add('contact')
  }
}

function startArtwork() {
  let container = document.querySelector('.canvas-wrapper')

  if (container) {
    new ArtFrame(container)
  }
}

import { Renderer, Triangle, Program, Mesh, Vec2 } from 'ogl'
import { vertex, homeFragment, contactFragment } from './shaders'
import { medium, large } from '../breakpoints'

class ArtFrame {
  constructor(container) {
    this.container = container

    this.renderer = new Renderer()
    this.gl = this.renderer.gl
    this.container.appendChild(this.gl.canvas)
    this.gl.clearColor(1, 1, 1, 1)

    this.geometry = new Triangle(this.gl)

    // Determine the fragment shader based on the route
    const currentPath = window.location.pathname
    const fragment = currentPath === '/' ? homeFragment : contactFragment

    this.program = new Program(this.gl, {
      vertex,
      fragment,
      uniforms: {
        uTime: { value: 0 },
        uResolution: { value: new Vec2() },
        uRepeat: { value: new Vec2(5, 5) },
      },
    })

    this.mesh = new Mesh(this.gl, { geometry: this.geometry, program: this.program })

    this.resize = this.resize.bind(this)
    this.update = this.update.bind(this)

    window.addEventListener('resize', this.resize, false)
    this.resize()
    requestAnimationFrame(this.update)
  }

  resize() {
    let { width, height } = this.container.getBoundingClientRect()

    this.program.uniforms.uResolution.value.set(Math.round(width), Math.round(height))

    this.renderer.setSize(width, height)

    if (width < medium) {
      this.program.uniforms.uRepeat.value.set(5, 5)
    } else if (width <= large) {
      this.program.uniforms.uRepeat.value.set(5, 5)
    } else {
      this.program.uniforms.uRepeat.value.set(6, 5)
    }
  }

  update(t) {
    requestAnimationFrame(this.update)

    this.program.uniforms.uTime.value = t * 0.00056

    this.renderer.render({ scene: this.mesh })
  }
}

export { ArtFrame }

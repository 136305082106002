/* -------------------------------------------------------------------------- */
/*                                VERTEX SHADER                               */
/* -------------------------------------------------------------------------- */
const vertex = /* glsl */ `
attribute vec2 uv;
attribute vec2 position;

varying vec2 vUv;

void main() {
    vUv = uv;
    gl_Position = vec4(position, 0, 1);
}
`

/* -------------------------------------------------------------------------- */
/*                                HOME ARTWORK                                */
/* -------------------------------------------------------------------------- */
const homeFragment = /* glsl */ `
precision highp float;

uniform float uTime;

uniform vec2 uResolution;

varying vec2 vUv;

vec2 rotate(vec2 v, float a) {
    float s = sin(a);
	  float c = cos(a);
	  mat2 m = mat2(c, s, -s, c);
	  return m * v;
}

vec3 palette(float t, vec3 a, vec3 b, vec3 c, vec3 d) {
    return a + b * cos(6.28318 * (c * t + d));
}

// Artwork by Mario Carrillo
void main() {
    vec2 uv = gl_FragCoord.xy / uResolution.xy;;
    
    float t = uTime;

    vec2 rp = vec2(10.0); 
    vec2 ruv = fract(uv * rp); 
    vec2 id = floor(uv * rp);

    float x = 1.0 - (ruv.x + sin(uv.y + id.x * 0.5 + t * 2.0) * 0.5 + 0.5);
    vec3 phx = vec3(sin((uv.x + uv.y) * 3.0 + t) * 0.5 + 0.5, 0.10, 0.0);
    vec3 color = palette(x, vec3(0.5), vec3(0.5), vec3(0.4), phx);

    gl_FragColor = vec4(color, 1.0);
}
`

/* -------------------------------------------------------------------------- */
/*                               CONTACT ARTWORK                              */
/* -------------------------------------------------------------------------- */
const contactFragment = /* glsl */ `
precision highp float;

#define PI 3.1415926535897932384626433832795
#define TWO_PI 6.2831853071795864769252867665590

uniform float uTime;

uniform vec2 uRepeat;
uniform vec2 uResolution;

varying vec2 vUv;

float random(vec2 st) {
    return fract(sin(dot(st.xy, vec2(12.9898, 78.233))) * 43758.5453);
}

float sdBox(in vec2 p, in vec2 b) {
    vec2 d = abs(p)-b;
    return length(max(d,0.0)) + min(max(d.x,d.y),0.0);
}

vec3 palette(float t, vec3 a, vec3 b, vec3 c, vec3 d) {
    return a + b * cos(6.28318 * (c * t + d));
}

// Artwork by Mario Carrillo
void main() {
    vec2 uv = gl_FragCoord.xy / uResolution.xy;;

    float t = uTime;
    float gr = random(uv);

    vec2 fuv = fract(uv * uRepeat);
    vec2 id = floor(uv * uRepeat);

    float box = sdBox(fuv - 0.5, vec2(0.5));
    float odd = mod(id.x + id.y, 2.0);

    t = mix(-t, t, odd);
    
    float ph = mix(0.0, PI, odd);
    
    vec3 color = palette(box + t * 0.15 + ph, vec3(1.0, 0.5, 0.5), vec3(1.0, 0.35, 1.0), vec3(2.0, 2.0, 2.0), vec3(0.11, 0.23, 0.36));
    
    gl_FragColor = vec4(color, 1.0);
    gl_FragColor.rgb += gr * 0.075;
}
`

export { vertex, homeFragment, contactFragment }
